import React from "react"
import { StaticQuery, graphql } from "gatsby"

const Maps = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulTcStatic(contentful_id: {eq: "1vTHqQEWFwo4GpOxz4UARJ"}) {
          titleString
          id
          textContent {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `
    }
    render = {data => (

      <section class="section">
        <div class="container is-fluid wrapper standard-margin">
        <br/>
        <br/>
        <h1 class="title has-text-primary has-text-weight-light">{data.contentfulTcStatic.titleString}</h1>
        <h2 class="subtitle has-text-weight-light">
        <div dangerouslySetInnerHTML={{ __html: data.contentfulTcStatic.textContent.childMarkdownRemark.html}}/>
        </h2>
        <hr class="hr is-primary"/>
        <section class="hero is-small no-padding" >
        <iframe title="Google Maps Widget" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2503.8199566313183!2d6.9445341602338955!3d51.13022893803189!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf8cce7e64bacef02!2sReiseb%C3%BCro+travel+connection+Touristik+GmbH!5e0!3m2!1sde!2sde!4v1560688512517!5m2!1sde!2sde" style={{padding: "0", border: "0", width: "100%",  height: "500px"}} allowfullscreen></iframe>
        </section>
        </div>
      </section>

    )}
    />
    )

export default Maps
